// https://github.com/trill-corp/trill-feed-validator/blob/v1.0.28/src/index.js

import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";

import LogLevel from "./utils/LogLevel";

// ロギングモジュールの読み込み（参考：https://github.com/pimterry/loglevel）
window.LogLevel = LogLevel;

import App from "./App";

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
