// https://github.com/trill-corp/trill-feed-validator/blob/v1.0.28/src/components/TrillFeedItemCard.js

import React, { Component } from "react";
import PropTypes from "prop-types";

import { Card, Image, Icon, Label } from "semantic-ui-react";

import _ from "lodash";

const propTypes = {
  /**
   * フィードアイテム
   */
  item: PropTypes.object.isRequired,

  /**
   * プレビューリンクのクリックイベントハンドラー
   */
  onPreviewClick: PropTypes.func
};

const defaultProps = {
  item: {}
};

/**
 * フィード解析結果のフィードアイテムを表示するコンポーネント
 */
class TrillFeedItemCard extends Component {
  handlePreviewClick = (event) => {
    _.invoke(this.props, "onPreviewClick", event, { ...this.props });
  }

  render() {
    const { item } = this.props;

    return (
      <Card>
        <Image src={_.get(item, "image.url")} />

        {(item.hasWarnings || item.hasDescriptionWarnings) &&
          <Label corner="right" icon="warning sign" color="yellow" />
        }

        {item.hasDescriptionError &&
          <Label corner="right" icon="remove circle" color="red" />
        }

        <Card.Content textAlign="left">
          <Card.Header textAlign="left">
            {item.title}
          </Card.Header>

          <Card.Meta>
            {item.guid}
          </Card.Meta>

          <Card.Description>
            {item.summary}
          </Card.Description>
        </Card.Content>

        {/* 記事本文にエラーが無ければプレビューリンクを表示 */}
        {!item.hasDescriptionError &&
          <Card.Content extra textAlign="center">
            <a onClick={this.handlePreviewClick}>
              <Icon name="window restore" />
              記事本文をプレビュー
          </a>
          </Card.Content>
        }
      </Card>
    );
  }
}

TrillFeedItemCard.propTypes = propTypes;
TrillFeedItemCard.defaultProps = defaultProps;

export default TrillFeedItemCard;
