// https://github.com/trill-corp/trill-feed-validator/blob/v1.0.28/src/components/TrillFeedValidator.js

import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Dimmer, Loader, Form, Checkbox, Button, Menu, Header, List, Card, Modal, Divider
} from "semantic-ui-react";

import _ from "lodash";

import EventEmitter from "events";

import TrillFeedValidationMessageList from "../components/TrillFeedValidationMessageList";
import TrillFeedItemCard from "../components/TrillFeedItemCard";

import LogLevel from "../utils/LogLevel";

const http = require("http");

const https = require("https");

const logger = LogLevel.getLogger("Main");

const propTypes = {
  /**
   * 著作権情報を無視するオプションを表示するかどうか
   */
  showIgnoreCopyright: PropTypes.bool.isRequired
};

const defaultProps = {
  showIgnoreCopyright: false
};

class TrillFeedValidator extends Component {
  state = {
    isBusy: false,
    isIgnoreCopyrightChecked: false,
    isDescriptionPreviewModalOpen: false,
    items: [],
    errors: {},
    warnings: {},
    urlError: false,
    isValid: false,
    menuItemStatus: "file",
    feedUrl: "",
    relatedItems: []
  };

  /**
   * TRILL フィードの文字列
   */
  feedText = "";

  handleIgnoreCopyrightCheckboxChange = () => {
    this.setState({
      isIgnoreCopyrightChecked: !this.state.isIgnoreCopyrightChecked
    }, () => {
      // 著作権情報の無視オプションを切り替えたら再度フィードを読み込む
      this.readFeed();
    });
  }

  handleMenuItemClick = (e, { name }) => {
    this.setState({ menuItemStatus: name });
  }

  handleSelectFeedFileButtonClick = (event) => {
    event.preventDefault();

    this.refs.feedFileInput.click();
  }

  handleFeedFileInputChange = (event) => {
    event.preventDefault();

    if (_.isEmpty(event.target.files)) return;

    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      const feedText = reader.result;

      logger.debug("feed text:", feedText);

      this.readFeed(feedText);
    };

    reader.readAsText(file);
  }

  handleSelectFeedUrlButtonClick = (event) => {
    event.preventDefault();

    this.setState({ isBusy: true });

    const options = {
      host: window.location.hostname,
      port: window.location.port,
      path: `/v1/feed?url=${encodeURI(this.state.feedUrl)}`,
      method: "GET"
    };

    const client = window.location.protocol === "https" ? https : http;
    const req = client.request(options, (res) => {
      const { statusCode } = res;
      const chunks = [];

      res.setEncoding("utf8");
      res.on("data", (chunk) => {
        chunks.push(chunk);
      });
      res.on("end", () => {
        const xmlBody = _.join(chunks, "");
        if (statusCode === 200) {
          logger.debug(xmlBody);
          this.readFeed(xmlBody);
        } else {
          // TODO エラーメッセージとか
          this.setState({ isBusy: false });
        }
      });
    });

    req.end();
  }

  handleFeedUrlInputChange = (event) => {
    const URL_PATTERN = new RegExp("(http|https)://[\\w-]+(\\.[\\w-]+)*([\\w.,@?^=%&amp;:/~+#-]*[\\w@?^=%&amp;/~+#-])?");
    const feedUrl = event.target.value;
    const isValidUrl = URL_PATTERN.test(feedUrl);
    this.setState({ feedUrl, urlError: !isValidUrl, isValid: isValidUrl });
  }

  handleSubmit = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();

      if (this.state.menuItemStatus === "file") {
        this.refs.selectFeedFileButton.ref.click();
      } else {
        this.refs.selectFeedUrlButton.ref.click();
      }
    }
  }

  handleFeedItemCardPreviewClick = (event, { item }) => {
    this.setState({
      isDescriptionPreviewModalOpen: true,
      relatedItems: _.take(item.related_items, 3)
    }, () => {
      // TODO: iframe 表示サイズをブラウザーのウィンドウサイズに合わせて調整

      this.refs.descriptionPreviewIframe.src = `javascript: '${item.descriptionFullPage.replace(/'/g, "\\'")}'`;
    });
  }

  handleDescriptionPreviewModalClose = () => {
    this.setState({
      isDescriptionPreviewModalOpen: false,
      relatedItems: []
    });
  }

  handleTrillFeedReaderItem = () => {
  }

  readFeed(feedText = this.feedText) {
    if (_.isEmpty(feedText)) return;

    if (this.feedText !== feedText) {
      this.feedText = feedText;
    }

    const ignoreCopyright = this.state.isIgnoreCopyrightChecked;

    const event = new EventEmitter();

    // 記事本文の分析プロミス
    const descriptionParsePromises = [];

    const partialState = {
      isBusy: true,
      items: [],
      errors: {},
      warnings: {}
    };

    const postDataXml = JSON.stringify({feedText, ignoreCopyright});

    // Call API `/parse_xml` and use that result instead of TrillFeedReader
    this.parseXml(postDataXml).then((data) => {
      if (!_.isEmpty(data)){
        _.each(data.items, (item) => {
          event.emit('item', item)
        });

        _.each(data.warnings, (warning) => {
          event.emit('warning', warning.message, warning.article)
        });

        _.each(data.errors, (error) => {
          event.emit('error', error.message, error.article)
        });
      }
      event.emit('end');
    });

    event.on('item', (item) => {
      // TODO: Uncomment after migrate API /description + /feed complete
      //
      const postDataStr = JSON.stringify({description: item.description});

      const options = {
        host: window.location.hostname,
        port: window.location.port,
        path: `/v1/description?ignoreCopyright=${ignoreCopyright}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Content-Length': postDataStr.length
        }
      };

      partialState.items.push(item);

      descriptionParsePromises.push(new Promise((resolve) => {
        const client = window.location.protocol === 'https' ? https : http;
        const req = client.request(options, (res) => {
          const { statusCode } = res;
          const chunks = [];
          res.setEncoding('utf8');
          res.on('data', (chunk) => {
            chunks.push(chunk);
          });
          res.on('end', () => {
            const body = _.join(chunks, '');
            if (statusCode === 200) {
              const data = JSON.parse(body);

              if (!_.isEmpty(data.errors)) {
                _.each(data.errors, (errorMessage) => {
                  logger.debug('TrillDescription parse error:', errorMessage);

                  if (!_.has(partialState.errors, item.guid)) partialState.errors[item.guid] = [];
                  partialState.errors[item.guid].push(errorMessage);
                });

                item.hasDescriptionError = true;
              } else {
                if (!_.isEmpty(data.warnings)) {
                  _.each(data.warnings, (warningMessage) => {
                    logger.debug('TrillDescription parse warning:', warningMessage);

                    if (!_.has(partialState.warnings, item.guid)) partialState.warnings[item.guid] = [];
                    partialState.warnings[item.guid].push(warningMessage);
                  });

                  item.hasDescriptionWarnings = true;
                }

                item.descriptionFullPage = data.description_full_page;
              }
            } else if (statusCode === 400) {
              const data = JSON.parse(body);
              if (!_.has(partialState.errors, item.guid)) partialState.errors[item.guid] = [];
              partialState.errors[item.guid].push(data.message);

              item.hasDescriptionError = true;
            }
            resolve();
          });
        });
        req.write(postDataStr);
        req.end();
      }));
    });

    event.on('warning', (warningMessage, article) => {
      if (!_.has(partialState.warnings, article.guid)) partialState.warnings[article.guid] = [];
      partialState.warnings[article.guid].push(warningMessage);

      const targetItem = _.find(partialState.items, { guid: article.guid });

      if (targetItem) {
        targetItem.hasWarnings = true;
      }
    });

    event.on('error', (errorMessage, article) => {
      if (!_.has(partialState.errors, article.guid)) partialState.errors[article.guid] = [];
      partialState.errors[article.guid].push(errorMessage);
    });

    // 読み込み終了
    event.on('end', () => {
      // 記事本文の分析を全て待つ
      Promise.all(descriptionParsePromises).then(() => {
        partialState.isBusy = false;
        this.setState(partialState);
      });
    });

    // 読み込み開始
    this.setState(partialState);
  }

  parseXml(postDataXml) {
    return new Promise((resolve) => {
      const options = {
        host: window.location.hostname,
        port: window.location.port,
        path: '/v1/parse_xml',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Content-Length': postDataXml.length
        }
      };

      const client = window.location.protocol === 'https' ? https : http;
      const req = client.request(options, (res) => {
        const { statusCode } = res;
        const chunks = [];
        res.setEncoding('utf8');
        res.on('data', (chunk) => {
          chunks.push(chunk);
        });
        res.on('end', () => {
          const body = _.join(chunks, '');
          if (statusCode === 200) {
            const data = JSON.parse(body);
            return resolve(data);
          } else {
            return resolve();
          }
        });
      });
      req.write(postDataXml);
      req.end();
    });
  }

  render() {
    return (
      <div className="TrillFeedValidator">
        <Dimmer active={this.state.isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <Form onKeyDown={this.handleSubmit}>
          {/* フィード読み込みオプション */}
          {this.props.showIgnoreCopyright &&
            <Form.Field>
              <Checkbox toggle label="著作権情報を無視"
                checked={this.state.isIgnoreCopyrightChecked}
                onChange={this.handleIgnoreCopyrightCheckboxChange}
              />
            </Form.Field>
          }

          {/* 検証方法メニュー */}
          <Menu pointing secondary color="blue">
            <Menu.Item
              content="ファイルを指定する"
              name="file"
              onClick={this.handleMenuItemClick}
              active={_.isEqual(this.state.menuItemStatus, "file")}
            />
            <Menu.Item
              content="URLを指定する"
              name="url"
              onClick={this.handleMenuItemClick}
              active={_.isEqual(this.state.menuItemStatus, "url")}
            />
          </Menu>

          {/* フィードファイル選択ボタン */}
          <div style={{ display: _.isEqual(this.state.menuItemStatus, "file") ? "" : "none " }}>
            <Form.Field>
              <Button
                primary fluid
                icon="file image outline"
                content="TRILL フィードの XML ファイルを選択してください"
                ref="selectFeedFileButton"
                onClick={this.handleSelectFeedFileButtonClick}
              />

              <input
                style={{ display: "none" }}
                type="file"
                ref="feedFileInput"
                onChange={this.handleFeedFileInputChange}
              />
            </Form.Field>
          </div>

          {/* URL入力 */}
          <div style={{ display: _.isEqual(this.state.menuItemStatus, "url") ? "" : "none " }}>
          <Form.Field>
            <Form.Input
              type="text"
              ref="feedUrlInput"
              placeholder="TRILL フィードの URL"
              onChange={this.handleFeedUrlInputChange}
              error={ this.state.urlError }
            />

            <Divider hidden />

            <Button
              primary fluid
              icon="cloud"
              content="検証"
              ref="selectFeedUrlButton"
              onClick={this.handleSelectFeedUrlButtonClick}
              disabled={!this.state.isValid}
            />
          </Form.Field>
          </div>
        </Form>

        {/* フィードの警告 */}
        {!_.isEmpty(this.state.warnings) &&
          <TrillFeedValidationMessageList warnings={this.state.warnings} />
        }

        {/* フィードのエラー */}
        {!_.isEmpty(this.state.errors) &&
          <TrillFeedValidationMessageList errors={this.state.errors} />
        }

        {/* フィードのアイテム一覧 */}
        {!_.isEmpty(this.state.items) &&
          <div style={{ marginTop: "1rem" }}>
            <Header content="記事一覧" />

            <Card.Group doubling itemsPerRow={3}>
              {_.map(this.state.items, (item, key) => (
                <TrillFeedItemCard
                  key={key}
                  item={item}
                  onPreviewClick={this.handleFeedItemCardPreviewClick}
                />
              ))}
            </Card.Group>
          </div>
        }

        {/* 記事本文プレビューモーダル */}
        <Modal
          closeIcon
          open={this.state.isDescriptionPreviewModalOpen}
          onClose={this.handleDescriptionPreviewModalClose}
        >
          <Modal.Content>
            <iframe
              title="記事本文のプレビュー"
              ref="descriptionPreviewIframe"
              style={{ width: "100%", minHeight: "800px", border: "none" }}
            />

            {/* 関連記事 */}
            {this.state.relatedItems && this.state.relatedItems.length > 0 && (
              <div>
                <Header size="medium">関連する他の記事</Header>
                <List celled>
                  {_.map(this.state.relatedItems, item => (
                    <List.Item>
                      <List.Content>
                        <a href={ item.link } target="_blank" rel="noopener noreferrer">{ item.title }</a>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </div>
            )}
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

TrillFeedValidator.propTypes = propTypes;
TrillFeedValidator.defaultProps = defaultProps;

export default TrillFeedValidator;
