// https://github.com/trill-corp/trill-feed-validator/blob/v1.0.28/src/App.js

import React, { Component } from "react";

import { Container, Header, Icon } from "semantic-ui-react";

import _ from "lodash";
import qs from "query-string";

import TrillFeedValidator from "./components/TrillFeedValidator";

class App extends Component {
  state = {
    showIgnoreCopyright: false
  }

  constructor() {
    super();

    const query = qs.parse(window.location.search);

    _.set(this.state, "showIgnoreCopyright", _.has(query, "show-ignore-copyright"));
  }

  render() {
    return (
      <Container style={{ padding: "1rem" }}>
        <Header as="h1" dividing>
          <Icon name="feed" />

          <Header.Content>
            TRILL Feed Validator

            <Header.Subheader>
              TRILL フィード検証ツールです。
            </Header.Subheader>
          </Header.Content>
        </Header>

        <TrillFeedValidator showIgnoreCopyright={this.state.showIgnoreCopyright} />
      </Container>
    );
  }
}

export default App;
