// https://github.com/trill-corp/trill-feed-validator/blob/v1.0.28/src/components/TrillFeedValidationMessageList.js

import React, { Component } from "react";
import PropTypes from "prop-types";

import { Message, Segment, Accordion, Icon, List } from "semantic-ui-react";

import _ from "lodash";

const propTypes = {
  errors: PropTypes.array,
  warnings: PropTypes.array
};

const defaultProps = {
  errors: [],
  warnings: []
};

/**
 * フィード解析結果の情報を一覧で表示するコンポーネント
 */
class TrillFeedValidationMessageList extends Component {
  state = {
    activeIndex: -1
  };

  message = {
    error: {
      icon: "remove circle",
      header: "エラーがあります",
      content: "TRILL に取り込まれないため、修正が必要です。"
    },
    warning: {
      icon: "warning sign",
      header: "警告があります",
      content: "TRILL に配信保留の状態で取り込まれるため、修正することをおすすめします。"
    }
  };

  handleTitleClick = (event, titleProps) => {
    event.preventDefault();

    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({
      activeIndex: newIndex
    });
  }

  renderMessage = (d) => {
    const data = _.defaultTo(d.message, d);
    if (!_.isArray(data)) return data;
    return _.map(data, item => (
      <List.List>
        <List.Item style={{ whiteSpace: "pre-line" }}>
          {item}
        </List.Item>
      </List.List>
    ));
  }

  render() {
    const { errors, warnings } = this.props;
    const { activeIndex } = this.state;

    const isErrorList = _.isEmpty(warnings);

    const title = isErrorList ? `エラー件数: ${_.size(errors)} 件` : `警告の件数: ${_.size(warnings)} 件`;
    const data = isErrorList ? errors : warnings;
    const message = isErrorList ? _.get(this.message, "error") : _.get(this.message, "warning");

    return (
      <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Message
          icon={_.get(message, "icon")}
          header={_.get(message, "header")}
          content={_.get(message, "content")}
          negative={isErrorList}
          warning={!isErrorList}
          attached="top"
        />

        <Segment attached="bottom" style={{ padding: "0 1rem" }}>
          <Accordion fluid exclusive={false}>
            <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleTitleClick}>
              <Icon name="dropdown" />
              {title}
            </Accordion.Title>

            <Accordion.Content key={1} active={activeIndex === 1} style={{ padding: ".5rem 0" }}>
              <List divided relaxed>
                {_.map(data, (d, key) => (
                  <List.Item key={key}>
                    <List.Icon name="doctor" />
                    <List.Content>
                      <List.Header>GUID: {key}</List.Header>
                      {this.renderMessage(d)}
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </div>
    );
  }
}

TrillFeedValidationMessageList.propTypes = propTypes;
TrillFeedValidationMessageList.defaultProps = defaultProps;

export default TrillFeedValidationMessageList;
